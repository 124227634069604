<template>
  <div class="page--lipstickbar product-catalogue">
    <div class="container-lipstickbar">
      <router-link :to="`/lipstickbar/brands`" class="back-to">BACK</router-link>
      <!-- <img :src="`${$store.state.lipstickbar.brand.image}`" class="brand-image" v-if="$store.state.lipstickbar.brand" onerror="this.src='/static/img/default_img_brand.svg';">
			<img :src="getBrandFromLocal()" class="brand-image" v-else-if="getBrandFromLocal()" onerror="this.src='/static/img/default_img_brand.svg';">
			<img src="/static/img/default_img_brand.svg" class="brand-image" v-else> -->
      <!-- <p class="copywriting">Berdasarkan beauty profile,<br />berikut masker yang kami rekomendasikan untukmu.</p> -->

      <div class="product-recomendation-wrapper">
        <div v-if="isLoadingFinish" class="row">
          <template v-if="$SO.size(recomendedBrand)">
            <template v-for="(item, key) in recomendedBrand">
              <product
                :key="key"
                :item="item"
                linkdetail="/lipstickbar/product-detail"
                :pagename="'lipstick'"
              ></product>
            </template>
          </template>
          <template v-else>
            <p class="text-center">Data not found.</p>
          </template>
        </div>
        <div v-else class="state-loading">
          <div id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
        </div>
      </div>

      <div class="box">
        <p class="box__paraf">
          <img src="/static/img/lipstickbar/lipstickdesign-left.svg" class="box__paraf__icon box__paraf__icon-left" />
          Tidak dapat menemukan lipstick favoritmu? <br /><span class="bolder">Beauty Advisor</span> akan dengan senang
          hati membantu
          <img src="/static/img/lipstickbar/lipstickdesign-right.svg" class="box__paraf__icon box__paraf__icon-right" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ls from '~/src/modules/local-storage';
import product from '~/src/components/global/product-display/product-display-list';
const Config = require('~/config/default.env').default;

export default {
  name: 'lisptickbarProductCatalogue',
  components: {
    product,
  },
  data() {
    return {
      isLoadingFinish: false,
      recomendedBrand: '',
    };
  },
  computed: {
    brandId() {
      return this.$route.params.brand_id;
    },
  },
  created() {
    this.getRecomendedBrand();
  },
  methods: {
    getCookies() {
      if (Cookies.get('store_location_id') && Cookies.get('store_location_id') != undefined) {
        return Cookies.get('store_location_id');
      }
    },
    getRecomendedBrand() {
      this.isLoadingFinish = false;
      this.$MS_SOCO_PUBLIC_API_URL
        .get(
          `/offline-store/store-cms/products?screen=Lipstick%20Bar&store_location_id=${
            this.getCookies() ? this.getCookies() : Config.store_id
          }&brand=${this.brandId}`
        )
        .then((response) => {
          if (response.status === 200) {
            this.recomendedBrand = response.data.data.products;
          } else {
            this.recomendedBrand = [];
          }
          this.isLoadingFinish = true;
        })
        .catch((error) => {
          this.recomendedBrand = [];
          this.isLoadingFinish = true;
        });
    },
    getBrandFromLocal() {
      return ls.get('brand-image');
    },
  },
};
</script>
