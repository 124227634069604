var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"col-xs-4 col",attrs:{"to":_vm.linkToDetailPage}},[_c('div',{staticClass:"product-recomendation"},[(_vm.item.discount_details == 'percentage')?_c('span',{staticClass:"product-recomendation__discount"},[_vm._v(_vm._s(_vm.item.discount_details)+"%")]):_vm._e(),_c('div',{staticClass:"product-recomendation__thumb"},[(_vm.item.product_image_url || _vm.item.image_cover)?_c('img',{staticClass:"product-recomendation__img",attrs:{"src":_vm.item.product_image_url ? _vm.item.product_image_url : _vm.item.image_cover,"onerror":"this.src='/static/img/default_img_brand.svg';"}}):_c('img',{staticClass:"product-recomendation__img",attrs:{"src":"/static/img/default_img_brand.svg"}})]),_c('div',{staticClass:"product-recomendation__info"},[(_vm.item.brand || _vm.item.manufacturer_name)?_c('h2',{staticClass:"product-recomendation__brand-name"},[_vm._v(" "+_vm._s(_vm.item.brand && _vm.item.brand != undefined ? _vm.item.brand : _vm.item.manufacturer_name)+" ")]):_vm._e(),_c('p',{staticClass:"product-recomendation__product-name"},[_vm._v(" "+_vm._s(_vm.item.name && _vm.item.name != undefined ? _vm.item.name : _vm.item.product_name)+" ")]),_c('div',{staticClass:"price"},[('discount' in _vm.item && _vm.item.discount && _vm.item.price_after_discount < _vm.item.price)?_c('p',{staticClass:"product-recomendation__price-old"},[_vm._v(" Rp "+_vm._s(_vm._f("currency")(_vm.item.price))+" ")]):_vm._e(),(
              'price_after_discount' in _vm.item &&
              _vm.item.price_after_discount > 0 &&
              'discount' in _vm.item &&
              _vm.item.discount_value &&
              _vm.item.discount_type == 'percent'
            )?_c('p',{staticClass:"product-recomendation__price-discount"},[_vm._v(" SAVE "+_vm._s(_vm.item.discount_value)+"% ")]):(
              'price_after_discount' in _vm.item &&
              _vm.item.price_after_discount > 0 &&
              'discount' in _vm.item &&
              _vm.item.discount_value &&
              _vm.item.discount_type == 'amount'
            )?_c('p',{staticClass:"product-recomendation__price-discount"},[_vm._v(" SAVE -Rp. "+_vm._s(_vm._f("currency")(_vm.item.discount_value))+" ")]):void 0,('price_after_discount' in _vm.item && _vm.item.price_after_discount > 0)?_c('p',{staticClass:"product-recomendation__price-total"},[_vm._v(" Rp "+_vm._s(_vm._f("currency")(_vm.item.price_after_discount))+" ")]):_c('span',{staticClass:"product-recomendation__price"},[_vm._v(" "+_vm._s(_vm.item.price)+" ")]),(
              'price_after_discount' in _vm.item &&
              _vm.item.price_after_discount > 0 &&
              'discount' in _vm.item &&
              _vm.item.discount_value
            )?_c('p',{staticClass:"product-recomendation__discount"},[_vm._v(" "+_vm._s(_vm.item.discount_type == 'amount' ? 'SALE' : _vm.item.discount_value + '%')+" ")]):_vm._e()],2)]),_c('div',{staticClass:"product-recomendation__rating"},[_c('div',{staticClass:"rating--uniform timeline-review"},[_c('div',{staticClass:"rate-star--uniform",class:'rate-star--uniform-' + _vm.$options.filters.rating(_vm.item.rating)},[_c('span'),_c('span'),_c('span'),_c('span'),_c('span')]),_c('span',{staticClass:"total-review"},[_vm._v("("+_vm._s(_vm.item.total_review ? _vm.item.total_review : '0')+")")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }