<template>
  <router-link class="col-xs-4 col" :to="linkToDetailPage">
    <div class="product-recomendation">
      <span v-if="item.discount_details == 'percentage'" class="product-recomendation__discount"
        >{{ item.discount_details }}%</span
      >

      <div class="product-recomendation__thumb">
        <img
          v-if="item.product_image_url || item.image_cover"
          :src="item.product_image_url ? item.product_image_url : item.image_cover"
          class="product-recomendation__img"
          onerror="this.src='/static/img/default_img_brand.svg';"
        />
        <img v-else src="/static/img/default_img_brand.svg" class="product-recomendation__img" />
      </div>

      <div class="product-recomendation__info">
        <h2 v-if="item.brand || item.manufacturer_name" class="product-recomendation__brand-name">
          {{ item.brand && item.brand != undefined ? item.brand : item.manufacturer_name }}
        </h2>
        <p class="product-recomendation__product-name">
          {{ item.name && item.name != undefined ? item.name : item.product_name }}
        </p>
        <!--
				<div class="group-price">
					<h5 class="product-recomendation__price-total">{{ item.price }}</h5>
				</div> -->
        <div class="price">
          <p
            v-if="'discount' in item && item.discount && item.price_after_discount < item.price"
            class="product-recomendation__price-old"
          >
            Rp {{ item.price | currency }}
          </p>
          <p
            v-if="
              'price_after_discount' in item &&
              item.price_after_discount > 0 &&
              'discount' in item &&
              item.discount_value &&
              item.discount_type == 'percent'
            "
            class="product-recomendation__price-discount"
          >
            SAVE {{ item.discount_value }}%
          </p>
          <p
            v-else-if="
              'price_after_discount' in item &&
              item.price_after_discount > 0 &&
              'discount' in item &&
              item.discount_value &&
              item.discount_type == 'amount'
            "
            class="product-recomendation__price-discount"
          >
            SAVE -Rp. {{ item.discount_value | currency }}
          </p>
          <template v-else></template>
          <p
            v-if="'price_after_discount' in item && item.price_after_discount > 0"
            class="product-recomendation__price-total"
          >
            Rp {{ item.price_after_discount | currency }}
          </p>
          <span v-else class="product-recomendation__price">
            {{ item.price }}
          </span>
          <p
            v-if="
              'price_after_discount' in item &&
              item.price_after_discount > 0 &&
              'discount' in item &&
              item.discount_value
            "
            class="product-recomendation__discount"
          >
            {{ item.discount_type == 'amount' ? 'SALE' : item.discount_value + '%' }}
          </p>
        </div>
      </div>

      <div class="product-recomendation__rating">
        <div class="rating--uniform timeline-review">
          <div class="rate-star--uniform" :class="'rate-star--uniform-' + $options.filters.rating(item.rating)">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <span class="total-review">({{ item.total_review ? item.total_review : '0' }})</span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'ProductDisplayList',
  props: ['item', 'linkdetail', 'pagename'],
  computed: {
    linkToDetailPage() {
      if (this.pagename === 'lipstick') {
        return `${this.linkdetail}/${this.item.id_product_soco}`;
      } else {
        return `${this.linkdetail}/${this.item.id_product}`;
      }
    },
  },
  created() {
    // console.log(this.item.manufacturer_name)
  },
};
</script>
